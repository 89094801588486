import React, { useRef, useEffect, useState } from "react";

const ParticlesBackground = ({ theme, showParticles, clicks }) => {
    const canvasRef = useRef(null);
    const animationFrameIdRef = useRef(null);
    const [particlesArray, setParticlesArray] = useState([]);

    const mouse = {
        x: null,
        y: null,
        radius: 80,
    };

    // Initialize particles
    useEffect(() => {
        let timeoutId;

        const generateParticles = () => {
            const canvas = canvasRef.current;

            class Particle {
                constructor() {
                    this.x = Math.random() * canvas.width;
                    this.y = Math.random() * canvas.height;
                    this.size = Math.random() * 5 + 30;
                    this.speedX = Math.random() * 4 - 2;
                    this.speedY = Math.random() * 4 - 2;
                    this.originalSpeedX = this.speedX;
                    this.originalSpeedY = this.speedY;
                    this.maxSpeed = 5;
                    this.color = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255}`;
                    this.opacity = 0;
                }

                update() {
                    this.x += this.speedX;
                    this.y += this.speedY;

                    if (this.opacity < 1) this.opacity += 0.02;

                    let dx = mouse.x - this.x;
                    let dy = mouse.y - this.y;
                    let dist = Math.sqrt(dx * dx + dy * dy);

                    // Repel particle if mouse near
                    if (dist < mouse.radius) {
                        let angle = Math.atan2(dy, dx);

                        // Increase speed in other direction
                        this.speedX = this.speedX - Math.cos(angle);
                        this.speedY = this.speedY - Math.sin(angle);

                        // Limit speed
                        if (this.speedX > this.maxSpeed) this.speedX = this.maxSpeed;
                        if (this.speedX < -this.maxSpeed) this.speedX = -this.maxSpeed;
                        if (this.speedY > this.maxSpeed) this.speedY = this.maxSpeed;
                        if (this.speedY < -this.maxSpeed) this.speedY = -this.maxSpeed;
                    }

                    const drag = 0.01;
                    this.speedX += (this.originalSpeedX - this.speedX) * drag;
                    this.speedY += (this.originalSpeedY - this.speedY) * drag;

                    // Particle Boundary
                    if (this.x - this.size < 0 || this.x + this.size > canvas.width) {
                        this.speedX *= -1;
                        // Flip the original speed to match
                        if (Math.sign(this.speedX) !== Math.sign(this.originalSpeedX)) {
                            this.originalSpeedX *= -1;
                        }
                        // Ensure the particle stays within the canvas
                        this.x = this.x - this.size < 0 ? this.size : canvas.width - this.size;
                    }
                    if (this.y - this.size < 0 || this.y + this.size > canvas.height) {
                        this.speedY *= -1;
                        // Flip the original speed to match
                        if (Math.sign(this.speedY) !== Math.sign(this.originalSpeedY)) {
                            this.originalSpeedY *= -1;
                        }
                        // Ensure the particle stays within the canvas
                        this.y = this.y - this.size < 0 ? this.size : canvas.height - this.size;
                    }
                }

                draw(ctx) {
                    ctx.fillStyle = `${this.color},${this.opacity})`;
                    ctx.strokeStyle = `${this.color},${this.opacity})`;
                    ctx.lineWidth = 2;
                    ctx.beginPath();
                    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                    ctx.closePath();
                    ctx.fill();
                    ctx.stroke();
                }
            }

            const tempParticlesArray = [];

            for (let i = 0; i < 25; i++) {
                tempParticlesArray.push(new Particle());
            }

            setParticlesArray(tempParticlesArray);

            setParticlesArray(tempParticlesArray);
        };

        // Fade out transition complete before regenerating
        if (showParticles) {
            generateParticles();
        } else {
            timeoutId = setTimeout(generateParticles, 500);
        }

        // Cleanup
        return () => {
            clearTimeout(timeoutId);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clicks]);

    // Handle theme and showParticles changes
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            ctx.fillStyle = theme.body;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        };

        resizeCanvas();
        window.addEventListener("resize", resizeCanvas);

        window.addEventListener("mousemove", function (event) {
            var rect = canvas.getBoundingClientRect();
            mouse.x = event.clientX - rect.left;
            mouse.y = event.clientY - rect.top;
        });

        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < particlesArray.length; i++) {
                particlesArray[i].update();
                particlesArray[i].draw(ctx);
            }

            if (!showParticles && particlesArray.length === 0) {
                return; // Stop animation if particles are not shown and there are no particles
            }

            animationFrameIdRef.current = requestAnimationFrame(animate);
        }

        // Cancel any previous animation frame before starting a new one
        if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
        }

        animate();

        return () => {
            window.removeEventListener("resize", resizeCanvas);
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme.body, showParticles, particlesArray]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: -1,
                pointerEvents: "none",
                opacity: showParticles ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
            }}
        />
    );
};

export default ParticlesBackground;
