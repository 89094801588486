import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 80px);
    text-align: center;


    @media screen and (max-width: 800px) {
        grid-template-rows: repeat(7, 60px);
    }
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #01bf71;
        transition: 0.25s ease-in-out;
    }
`

export const ThemeBtn = styled.nav`

    display: none;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 60px;
    height: 60px;

    margin-top: 30px;

    @media screen and (max-width: 800px) {
        display: flex;
    }

    &:hover {
        transition: 0.2s ease-in-out;
        transform: scale(1.3);
    }
`

export const LogoBtn = styled.nav`

    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .7s ease-in-out;
    padding: 32px 64px;

    &:hover {
        transform: rotate(360deg);
    }
`

export const ThemeImage = styled.img`
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
