import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import RepoImage1 from '../assets/RepoImage1.png'
import RepoImage2 from '../assets/RepoImage2.png'
import RepoImage3 from '../assets/RepoImage3.JPG'
import RepoImage4 from '../assets/RepoImage4.JPG'

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const Projects = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div class="Projects min-h-screen"        >
            <div data-aos="fade-up" data-aos-easing="ease-in-out">
                <div class="text-5xl font-semibold text-center pt-10 pb-10">
                    Projects
                </div>
            </div>

            <div
                data-aos="zoom-in"
                data-aos-delay="40"
                data-aos-duration="800"
            >
                <div class="flex justify-center pt-10">
                    <Carousel showThumbs={false} useKeyboardArrows={true} infiniteLoop={true} showStatus={false}>
                        <div class="rounded-lg m-auto border shadow-lg bg-white max-w-sm">
                            <a href="#!">
                                <img class="rounded-t-lg" src={RepoImage3} alt="Repository Card" />
                            </a>
                            <div class="p-6">
                                <h5 class="text-gray-900 text-xl font-medium mb-2">CustomConnections.io</h5>
                                <p class="text-gray-700 text-base mb-4">
                                    CustomConnections.io is an online game, inspired by The New York Times's Connections game and the British TV quiz 'Only Connect', that allows users to create and share custom connections games using generated links.
                                </p>
                                <a href="https://imaad-dev.com/projectlink1" class="button" target="_blank" rel="noreferrer">
                                    <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Repository</button>
                                </a>
                            </div>
                            <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Typescript</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Express</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">NodeJS</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">MongoDB</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Docker</span>
                            </div>
                        </div>

                        <div class="rounded-lg m-auto border shadow-lg bg-white max-w-sm">
                            <a href="#!">
                                <img class="rounded-t-lg" src={RepoImage4} alt="Repository Card" />
                            </a>
                            <div class="p-6">
                                <h5 class="text-gray-900 text-xl font-medium mb-2">Distributed Newsletter Aggregator</h5>
                                <p class="text-gray-700 text-base mb-4">
                                    An application that aggregates news from an array of RSS feeds. Leveraging concurrency to fetch news, efficiently categorizing data and dispatching tailored newsletters based on user preferences.
                                </p>
                                <a href="https://imaad-dev.com/projectlink2" class="button" target="_blank" rel="noreferrer">
                                    <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Repository</button>
                                </a>
                            </div>
                            <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Go</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">C++</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">SQLite</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Cron Jobs</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">RSS Feeds</span>
                            </div>
                        </div>

                        <div class="rounded-lg m-auto border shadow-lg bg-white max-w-sm">
                            <a href="#!">
                                <img class="rounded-t-lg" src={RepoImage2} alt="Repository Card" />
                            </a>
                            <div class="p-6">
                                <h5 class="text-gray-900 text-xl font-medium mb-2">NASA Tweet Generator</h5>
                                <p class="text-gray-700 text-base mb-4">
                                    Generates tweets from NASA from a given starting seed, by using their public tweets as training data for an LSTM model
                                </p>
                                <a href="https://imaad-dev.com/projectlink3" class="button" target="_blank" rel="noreferrer">
                                    <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Repository</button>
                                </a>
                            </div>
                            <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Python</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Tensorflow</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Numpy</span>
                            </div>
                        </div>

                        <div class="rounded-lg m-auto border shadow-lg bg-white max-w-sm">
                            <a href="#!">
                                <img class="rounded-t-lg" src={RepoImage1} alt="Repository Card" />
                            </a>
                            <div class="p-6">
                                <h5 class="text-gray-900 text-xl font-medium mb-2">Dev Portfolio</h5>
                                <p class="text-gray-700 text-base mb-4">
                                    My developer portfolio website built from the group up using React
                                </p>
                                <a href="https://imaad-dev.com/projectlink4" class="button" target="_blank" rel="noreferrer">
                                    <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Repository</button>
                                </a>
                            </div>
                            <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">TailwindCSS</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">NodeJS</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Javascript</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">HTML</span>
                                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Git</span>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>

            <div class="push"></div>
        </div >
    )
}

export default Projects;