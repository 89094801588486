export const lightTheme = {
    body: 'linear-gradient(to right, #f5f7fa, #c3cfe2)',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#363537',
}
export const darkTheme = {
    body: 'linear-gradient(to right, #202020, #363636, #4b4b4b)',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#999',
}