import './webpages.css'
import resume from '../assets/resume.pdf'

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const Resume = ({ theme }) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div class="Resume min-h-screen">
            <div data-aos="fade-up" data-aos-easing="ease-in-out">
                <div class="pt-12 pb-6 relative flex py-5 items-center">
                    <div class="flex-grow border-t border-black"></div>
                    <span class="flex-shrink mx-4 text-5xl font-['Raleway']">Resume</span>
                    <div class="flex-grow border-t border-black"></div>
                </div>

                <div class="grid place-items-center h-screen">
                    <Document
                        file={resume}
                    >
                        <Page pageNumber={1} />
                    </Document>
                </div>

                <div className="pt-16 relative flex py-5 items-center">
                    <div className="flex-grow border-t border-black"></div>
                    <a href={resume} download="Resume" target="_blank" rel="noreferrer">
                        <button className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center transition duration-300 ease-in-out ${theme === 'light' ? 'text-black' : 'text-white'}`}>                           <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                        </svg>
                            <span className="text-lg">Download as PDF</span>
                        </button>
                    </a>
                    <div className="flex-grow border-t border-black"></div>
                </div>
            </div>
            <div class="push"></div>
        </div>
    )
}

export default Resume;