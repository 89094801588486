import { useEffect } from "react";

const MetaTags = () => {
    useEffect(() => {
        const metaTag = document.createElement("meta");
        metaTag.name = "robots";
        metaTag.content = "noindex";
        document.head.appendChild(metaTag);

        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);

    return null;
};

export default MetaTags;