import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/GlobalStyles";
import { lightTheme, darkTheme } from "./components/Themes"

import Home from './pages';
import About from './pages/about';
import Projects from './pages/projects';
import Experience from './pages/experience';
import Resume from './pages/resume';

import MetaTags from "./components/MetaTags";

function App() {
  const [theme, setTheme] = useState('light');

  const themeToggler = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }


  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <MetaTags />
      <div className="App">
        <Router>
          <Sidebar themeToggler={themeToggler} isOpen={isOpen} toggleOpen={toggleOpen} theme={theme} key={theme} />
          <Navbar themeToggler={themeToggler} toggleOpen={toggleOpen} theme={theme} />
          <Routes>
            <Route path='/' exact element={<Home theme={theme} />} />
            <Route path='/about' exact element={<About />} />
            <Route path='/projects' exact element={<Projects />} />
            <Route path='/experience' exact element={<Experience theme={theme} />} />
            <Route path='/resume' exact element={<Resume theme={theme} />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;