import SocialIcons from "./SocialIcons";

const Footer = () => {
    return (
        <footer className="bg-slate-800 text-white py-6">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center">
                    <span className="text-lg">
                        Imaad &copy; {new Date().getFullYear()}
                    </span>
                    <SocialIcons />
                </div>
            </div>
        </footer>
    );
};

export default Footer;