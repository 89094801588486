import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Chrono } from "react-chrono";

const Experience = ({ theme }) => {
    const [chronoKey, setChronoKey] = useState(0);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        // Update the key to trigger rerender when the theme prop changes
        setChronoKey(prevKey => prevKey + 1);
    }, [theme]);

    const items = [
        {
            title: "May 2024",
            cardTitle: "Tools Programming Intern @ Epic Games",
            url: "#",
            cardSubtitle: "Engine Optimization, Data Aggregation & Processing ",
            cardDetailedText: "Developed data aggregation and processing software related to engine performance in ICVFX projects using C#, C++, and BuildGraph. Data was stored and monitored via Datadog, and automated testing was performed through Horde, Epic’s CI/CD platform. Developed software using Python and C# to detect engine regressions in Unreal Engine 5 ICVFX projects, and surfaced these regressions to a custom internal dashboard with advanced filtering and external alerts. Contributed to Unreal Engine 5 performance optimization and extended Switchboard to support multi-node performance capture.",
        },
        {
            title: "June 2023",
            cardTitle: "Software and Automation Intern @ Aspire Software",
            url: "#",
            cardSubtitle: "Data Analysis and Workflow Automation",
            cardDetailedText: "Analyzed extensive business and customer datasets, utilizing machine learning and Python to identify key demographic characteristics of profitable customers, enhancing marketing effectiveness. Developed Python and JavaScript scripts to automate internal workflows, reducing manual tasks and increasing efficiency. Collaborated in the development of a RESTful API for automated website generation services, implementing a custom user interface to streamline content creation.",
        },
        {
            title: "May 2022",
            cardTitle: "Software Engineering Intern @ Premium Autogroup",
            url: "#",
            cardSubtitle: "Internal Tools and Data Collection",
            cardDetailedText: "Engineered a financial projection application using C# for real-time monitoring of profit/loss projections, enabling better-informed financial decisions. Constructed an internal survey tool using Python and JavaScript to track key performance metrics across the company, with results stored securely in SQL databases. Utilized Python and Matplotlib to model internal survey data, revealing key customer trends and contributing to strategic business adjustments.",
        },
        {
            title: "Dec 2022",
            cardTitle: "Teaching Assistant",
            url: "#",
            cardSubtitle: "Worked on Teaching and Leading",
            cardDetailedText: "Assessed curriculum needs and designed comprehensive lesson plans focusing on Java and Object-Oriented design principles. Led practical labs and tutorials, enhancing understanding of basic software engineering practices among students. Instructed a diverse class of 40+ students in software engineering concepts, Java, and OO design, facilitating deeper understanding and application of these concepts in practical assignments.",
        },
    ];

    const chronoTheme = {
        cardBgColor: theme === 'light' ? 'white' : '#3C3C3C',
        cardForeColor: theme === 'light' ? 'black' : 'white',
        titleColor: theme === 'light' ? 'black' : 'white',
    };

    return (
        <div class="Experience min-h-screen">
            <div data-aos="fade-up" data-aos-easing="ease-in-out">
                <div class="text-5xl font-semibold text-center pt-10 pb-10">
                    Experience
                </div>
            </div>

            <div
                data-aos="zoom-in"
                data-aos-delay="40"
                data-aos-duration="700"
                data-aos-once="true"
            >
                <div class="m-auto" style={{ width: '80vw', height: '200vh' }}>
                    <Chrono key={chronoKey} items={items} mode="VERTICAL_ALTERNATING" hideControls={true} theme={chronoTheme} />
                </div>
            </div>

            <div class="push"></div>
        </div>
    )
}

export default Experience;