const SocialIcons = () => {
    return (
        <div className="flex space-x-4">
            <SocialIcon
                href="https://imaad-dev.com/github123"
                imgSrc={require('../assets/logo-githubHR.png')}
                altText="Github"
            />
            <SocialIcon
                href="https://imaad-dev.com/linkedin123"
                imgSrc={require('../assets/logo-linkedinHR.png')}
                altText="LinkedIn"
            />
            <SocialIcon
                href="https://imaad-dev.com/mailme123"
                imgSrc={require('../assets/mail-outlineHR.png')}
                altText="Gmail"
            />
        </div>
    );
};

const SocialIcon = ({ href, imgSrc, altText }) => {
    return (
        <a
            href={href}
            className="p-2 rounded-full bg-slate-700 hover:bg-teal-500 transition-colors duration-300 inline-flex items-center justify-center w-12 h-12"
        >
            <img src={imgSrc} width={30} height={30} alt={altText} />
        </a>
    );
};

export default SocialIcons;