import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    ThemeBtn,
    ThemeImage
} from './NavbarElements'

const Navbar = ({ themeToggler, toggleOpen, theme }) => {
    return (
        <Nav>
            <NavLink to="/">
                <img src={require("../assets/logoHR.png")} width={194} height={60} alt="Logo" />
            </NavLink>
            <Bars onClick={toggleOpen} />
            <NavMenu>
                <NavLink to='/about'>
                    About
                </NavLink>
                <NavLink to='/projects'>
                    Projects
                </NavLink>
                <NavLink to='/experience'>
                    Experience
                </NavLink> 
                <NavLink to='/resume'>
                    Resume
                </NavLink>
                <NavLink as="a" href="https://imaad-dev.com/mailme123">
                    Contact
                </NavLink>
            </NavMenu>
            <ThemeBtn>
                <ThemeImage
                    visible={theme === 'light'}
                    src={require("../assets/moonHR.png")}
                    width={60} height={60}
                    alt="Theme Toggler"
                    onClick={themeToggler}
                />
                <ThemeImage
                    visible={theme !== 'light'}
                    src={require("../assets/sunHR.png")}
                    width={60} height={60}
                    alt="Theme Toggler"
                    onClick={themeToggler}
                />
            </ThemeBtn>
        </Nav>
    )
}

export default Navbar