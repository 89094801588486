import wave from '../assets/wave.gif'
import avatar from '../assets/avatar.png'

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './webpages.css';

import ParticlesBackground from "../components/ParticlesBackground";
import { lightTheme, darkTheme } from "../components/Themes";

const Home = ({ theme }) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const [showParticles, setShowParticles] = useState(false);
    const [clicks, setClicks] = useState(0);

    return (
        <div className="Homepage min-h-screen position-relative">
            <ParticlesBackground
                theme={theme === "light" ? lightTheme : darkTheme}
                showParticles={showParticles}
                clicks={clicks}
            />
            <div
                data-aos="fade-right"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
            >
                <h1 className='pt-6'>
                    Hi there <img src={wave} alt="Wave" className="Wave" />
                </h1>
            </div>
            <h2>
                <div
                    data-aos="zoom-in-left"
                    data-aos-delay="30"
                    data-aos-duration="800"
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                >
                    <span className="NameWrapper">
                        <span className="NamePrefix">I'm</span>{" "}
                        <span className="Name" onClick={() => { setShowParticles(!showParticles); setClicks(prev => prev + 1); }}>
                            Imaad
                        </span>
                    </span>
                </div>
            </h2>

            <div
                data-aos="zoom-out"
                data-aos-delay="80"
                data-aos-duration="1300"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
            >
                <img src={avatar} alt="Avatar Icon" className="Avatar"></img>
            </div>

            <div
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1600"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
            >
                <h3>
                    This is my developer portfolio
                </h3>
            </div>

            <div className="push"></div>
        </div >
    )
}

export default Home;