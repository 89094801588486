import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Lottie from "lottie-react";
import buildAnimation from "../assets/lottie/build.json";
import programmingAnimation from "../assets/lottie/programming.json";

import UniLogo from '../assets/uni.png'

import { Icon } from '@iconify/react';

const About = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const getAge = () => {
        var a = require("indefinite");
        var currDate = new Date();
        var birthDate = new Date("2003/11/11");
        var difference = new Date(currDate - birthDate);
        var age = difference.getUTCFullYear() - 1970;
        return a(age);
    }

    return (
        <div class="About min-h-screen">
            <div
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
            >
                <h1 className='pt-6 pb-6'>
                    About <span class="Name">me</span>
                </h1>
            </div>

            <div className="flex">
                <div
                    data-aos="fade-right"
                    data-aos-delay="65"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <h2 className='pl-10 pt-20 text-left'>
                        <div class="Introduction1">
                            My name is <span class="Name">Imaad</span>.
                            I am {getAge()} year old student majoring in computer science.
                        </div>
                    </h2>
                </div>

                <div
                    data-aos="fade-left"
                    data-aos-delay="65"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    className="lottie max-w-[50%] pr-10 pb-10"
                >
                    <Lottie animationData={programmingAnimation} loop={true} autoplay={true} />
                </div>
            </div>

            <div className="flex">
                <div
                    data-aos="fade-right"
                    data-aos-delay="80"
                    data-aos-duration="1100"
                    data-aos-easing="ease-in-out"
                    className="lottie max-w-[50%] pl-10"
                >
                    <Lottie animationData={buildAnimation} loop={true} autoplay={true} />
                </div>

                <div
                    data-aos="fade-left"
                    data-aos-delay="80"
                    data-aos-duration="1100"
                    data-aos-easing="ease-in-out"
                >
                    <h2 className='pr-10 pt-20 text-right'>
                        <div class="Introduction2">
                            I'm very passionate about all aspects of computer science and love learning new technologies,
                            problem solving and building applications.
                        </div>
                    </h2>
                </div>
            </div>

            <div class="push"></div>
            <div class="push"></div>

            <div class="section-container">
                <div
                    data-aos="zoom-out"
                    data-aos-delay="40"
                    data-aos-duration="800"
                >
                    <section>
                        <span class="skills-name1">My </span><span class="skills-name2">Skills</span>
                        <ul class="skills-icons">
                            <li><span><Icon icon="vscode-icons:file-type-python" height={85} /></span><span>Python</span></li>
                            <li><span><Icon icon="logos:c-sharp" height={85} /></span><span>C#</span></li>
                            <li><span><Icon icon="skill-icons:cpp" height={85} /></span><span>C++</span></li>
                            <li><span><Icon icon="mdi:language-c" height={85} /></span><span>C</span></li>
                            <li><span><Icon icon="logos:java" height={85} /></span><span>Java</span></li>
                            <li><span><Icon icon="logos:javascript" height={85} /></span><span>Javascript</span></li>
                            <li><span><Icon icon="vscode-icons:file-type-reactjs" height={85} /></span><span>React</span></li>
                            <li><span><Icon icon="vscode-icons:file-type-html" height={85} /></span><span>HTML</span></li>
                            <li><span><Icon icon="logos:go" height={70} /></span><span>Go</span></li>
                            <li><span><Icon icon="vscode-icons:file-type-matlab" height={85} /></span><span>MATLAB</span></li>
                        </ul>
                    </section>
                </div>
            </div>

            <div class="push"></div>
            <div class="push"></div>

            <div class="Education pl-10">
                <div
                    data-aos="fade-right"
                    data-aos-delay="80"
                    data-aos-duration="1000"
                    data-aos-anchor-placement="bottom-bottom"
                    data-aos-easing="ease-in-out"
                >
                    <div class="text-5xl font-semibold text-left pb-10">Education</div>
                    <div class="education-card flex">
                        <img src={UniLogo} alt="Unviersity Logo" class="UniLogo"></img>
                        <div class="education-card flex flex-wrap text-left pl-5">
                            <div class="text-lg">EST 2026</div>
                            <div class="break"></div>
                            <div class="text-2xl">Arizona State University - Bachelor of Computer Science</div>
                            <div class="break"></div>
                            <div class="text-lg">GPA: 4.0</div>
                            <div class="break"></div>
                            <ul>
                                <li class="text-lg">Major in Computer Science</li>
                                <li class="text-lg">Minor in Mathematics</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="push"></div>
            <div class="push"></div>
        </div >
    )
}

export default About;