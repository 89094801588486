import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    ThemeBtn,
    LogoBtn,
    ThemeImage
} from './SidebarElements'

const Sidebar = ({ themeToggler, isOpen, toggleOpen, theme }) => {
    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggleOpen}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/" onClick={toggleOpen}>
                        <LogoBtn>
                            <img src={require("../assets/logoHR.png")} width={194} height={60} alt="Logo" />
                        </LogoBtn>
                    </SidebarLink>
                    <p></p>
                    <SidebarLink to="/about" onClick={toggleOpen}>
                        About
                    </SidebarLink>
                    <SidebarLink to="/projects" onClick={toggleOpen}>
                        Projects
                    </SidebarLink>
                    <SidebarLink to="/experience" onClick={toggleOpen}>
                        Experience
                    </SidebarLink>
                    <SidebarLink to="/resume" onClick={toggleOpen}>
                        Resume
                    </SidebarLink>
                    <SidebarLink as="a" href="mailto:imaadf.work@gmail.com?subject=Inquiry" target="_blank" rel="noreferrer">
                        Contact
                    </SidebarLink>
                </SidebarMenu>
                <ThemeBtn>
                    <ThemeImage
                        visible={theme === 'light'}
                        src={require("../assets/moonHR.png")}
                        width={60} height={60}
                        alt="Theme Toggler"
                        onClick={themeToggler}
                    />
                    <ThemeImage
                        visible={theme !== 'light'}
                        src={require("../assets/sunHR.png")}
                        width={60} height={60}
                        alt="Theme Toggler"
                        onClick={themeToggler}
                    />
                </ThemeBtn>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar